<template>
  <!-- begin register -->
  <div id="content" class="page-content col-lg-12 col-xl-9">
    <!-- <div class="right-content"> -->
    <!-- begin card -->
    <div class="card bg-grey-lighter m-b-20 col-md-12">
      <div class="card-body">
        <blockquote class="blockquote">
          <h2 class="page-header text-left text-black-lighter">企业信息</h2>
        </blockquote>
      </div>
    </div>
    <!-- end card -->
    <div class="register-content col-md-12">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse" data-sortable-id="form-stuff-1">
          <div class="panel-body">
            <div class="row form-group m-b-10">
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >公司名称：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <input
                  class="form-control"
                  data-parsley-range="[5,10]"
                  v-model="BasicInfomation.name"
                />
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >社会信用代码：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <div class="input-group">
                  <masked-input
                    pattern="11111111111111111A"
                    class="form-control"
                    v-model="BasicInfomation.creditCode"
                    placeholder="XXXXXXXXXXXXXXXXXA"
                  ></masked-input>
                </div>
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >所属行业：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <v-select-tree :data="treeData1" />
                <!-- <select class="form-control" v-model="BasicInfomation.industryId">
              <option>1</option>
            </select> -->
              </div>
              <label class="col-lg-2 col-md-3 col-form-label text-right"
                >所属网格：</label
              >
              <div class="col-lg-4 col-md-9 m-b-10">
                <select
                  class="form-control"
                  v-model="BasicInfomation.gridId"
                  data-parsley-required="true"
                >
                  <option>1</option>
                </select>
              </div>
            </div>
          </div>
          <!-- </div>
        <div class="row form-group m-b-10"> -->
        </div>
        <!-- radio-begin -->
        <div class="form-group row m-b-1">
          <label class="col-md-2 col-form-label text-right">安全性：</label>
          <div class="col-md-9">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                name="default_radio"
                id="inlineCssRadio1"
                v-model="BasicInfomation.safetyLevel"
                value="0"
                checked
              />
              <label for="inlineCssRadio1" class="text-red-lighter">
                <span class="label f-s-12 label-danger">高危</span></label
              >
            </div>
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                v-model="BasicInfomation.safetyLevel"
                id="inlineCssRadio2"
                value="1"
              />
              <label for="inlineCssRadio2" class="text-yellow-lighter"
                ><span class="label f-s-12 label-warning">危险</span></label
              >
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                v-model="BasicInfomation.safetyLevel"
                id="inlineCssRadio3"
                value="3"
              />
              <label for="inlineCssRadio3" class="text-green-lighter"
                >安全</label
              >
            </div>
          </div>
        </div>
        <!-- radio-end -->
        <div class="row form-group">
          <label class="col-md-2 col-form-label text-right">企业地址：</label>
          <div class="col-md-10">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                v-model="BasicInfomation.address"
              />
            </div>
          </div>
        </div>
        <!-- begin row -->
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right">法人代表：</label>
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                data-parsley-range="[5,10]"
                v-model="BasicInfomation.legal"
              />
            </div>
          </div>
          <label class="col-md-2 col-form-label text-right">法人身份证：</label>
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                data-parsley-required="true"
                v-model="BasicInfomation.legalIdCard"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- begin  row -->
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right">联系电话：</label>
          <div class="col-md-4">
            <div class="input-group">
              <masked-input
                pattern="111-1111-1111"
                class="form-control"
                v-model="BasicInfomation.legalTel"
                placeholder="999-9999-9999"
              ></masked-input>
            </div>
          </div>
          <label class="col-md-2 col-form-label text-right">主要产品：</label>
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                v-model="BasicInfomation.mainProduct"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- begin  row -->
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right"
            >生产许可编号：</label
          >
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                data-parsley-range="[5,10]"
                v-model="BasicInfomation.productionNum"
              />
            </div>
          </div>
          <label class="col-md-2 col-form-label text-right">从业人数：</label>
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                v-model="BasicInfomation.jobNum"
                placeholder="0"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- begin  row -->
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right">建筑面积：</label>
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                data-parsley-range="[5,10]"
                v-model="BasicInfomation.floorage"
                placeholder="0.00"
              />
            </div>
          </div>
          <label class="col-md-2 col-form-label text-right">产值：</label>
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                v-model="BasicInfomation.outputValue"
                placeholder="0.00"
              />
            </div>
          </div>
        </div>
        <!-- end row -->
        <!-- radio-begin -->
        <div class="form-group row m-b-10">
          <label class="col-md-2 col-form-label text-right">有危化品：</label>
          <div class="col-md-9">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                id="inlineCssRadio4"
                v-model="BasicInfomation.isDangerous"
                value="true"
                checked
              />
              <label for="inlineCssRadio4">是</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                v-model="BasicInfomation.isDangerous"
                id="inlineCssRadio5"
                value="false"
              />
              <label for="inlineCssRadio5">否</label>
            </div>
          </div>
        </div>
        <hr class="m-b-40" />
        <h5 class="width-150">证件上传</h5>
        <div class="form-group row m-b-12">
          <label class="col-md-2 col-form-label text-right">营业执照：</label>
          <div class="page-content col-md-4">
            <div>
              <upload
                :maxSize="10"
                @finishUpload="finishAll"
                :id="['3a01a2f4-72ae-5f2d-713f-b153dfcc7b5f']"
              >
              </upload>
            </div>
          </div>
          <label class="col-md-2 col-form-label text-right">生产许可证：</label>
          <div class="page-content col-md-4">
            <div>
              <upload :maxSize="10" @finishUpload="finishAll1" :id="d.file2">
              </upload>
            </div>
          </div>
        </div>
        <hr class="m-b-40" />
        <!-- radio-end -->
        <h5 class="width-150">主要负责人</h5>
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right">姓名：</label>
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                v-model="BasicInfomation.mainUserName"
              />
            </div>
          </div>
          <label class="col-md-2 col-form-label text-right">电话号码：</label>
          <div class="col-md-4">
            <div class="input-group">
              <masked-input
                pattern="111-1111-1111"
                v-model="BasicInfomation.mainTel"
                class="form-control"
                placeholder="999-9999-9999"
              ></masked-input>
            </div>
          </div>
        </div>
        <!-- radio-begin -->
        <div class="form-group row m-b-10">
          <label class="col-md-2 col-form-label text-right">是否持证：</label>
          <div class="col-md-4">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                id="CertRadio1"
                v-model="BasicInfomation.mainIsCard"
                value="true"
                checked
              />
              <label for="CertRadio1">是</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                v-model="BasicInfomation.mainIsCard"
                id="CertRadio2"
                value="false"
              />
              <label for="CertRadio2">否</label>
            </div>
          </div>
          <label class="col-md-2 col-form-label text-right"
            >安全生产诚信等级：</label
          >
          <div class="col-md-4">
            <select
              class="form-control"
              v-model="BasicInfomation.mainSincerityLevel"
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
        <!-- radio-end -->
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right">证件号：</label>
          <div class="col-md-4">
            <div class="input-group">
              <input
                class="form-control"
                v-model="BasicInfomation.mainIdCard"
              />
            </div>
          </div>
          <label class="col-md-2 col-form-label text-right">证件有效期：</label>
          <div class="col-md-4">
            <div class="input-group">
              <date-picker
                id="dateTimeInput"
                v-model="BasicInfomation.mainValidity"
              ></date-picker>
              <div class="input-group-append">
                <label for="dateTimeInput" class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right">标准等级：</label>
          <div class="col-md-4">
            <select
              class="form-control"
              v-model="BasicInfomation.mainStandardLevel"
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <label class="col-md-2 col-form-label text-right">安全等级：</label>
          <div class="col-md-4">
            <div class="input-group">
              <select
                class="form-control"
                v-model="BasicInfomation.mainSafeLevel"
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>
        </div>
        <hr class="m-b-40" />
        <h5 class="width-150">制度建设</h5>
        <!-- radio-begin -->
        <div class="form-group row m-b-10">
          <label class="col-md-3 col-form-label text-right"
            >有安全生产责任书</label
          >
          <div class="col-md-2">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                v-model="BasicInfomation.isSafeBook"
                id="CertRadio3"
                value="true"
                checked
              />
              <label for="CertRadio3">是</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                id="CertRadio4"
                value="false"
                v-model="BasicInfomation.isSafeBook"
              />
              <label for="CertRadio4">否</label>
            </div>
          </div>
          <label class="col-md-3 col-form-label text-right"
            >有教育培训制度</label
          >
          <div class="col-md-2">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                id="CertRadio5"
                v-model="BasicInfomation.isEduTrain"
                value="true"
                checked
              />
              <label for="CertRadio5">是</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                id="CertRadio6"
                value="false"
                v-model="BasicInfomation.isEduTrain"
              />
              <label for="CertRadio6">否</label>
            </div>
          </div>
        </div>
        <!-- radio-end -->
        <!-- radio-begin -->
        <div class="form-group row m-b-10">
          <label class="col-md-3 col-form-label text-right"
            >有安全生产规章制度及操作规范</label
          >
          <div class="col-md-2">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                v-model="BasicInfomation.isFGMP"
                id="CertRadio7"
                value="true"
                checked
              />
              <label for="CertRadio7">是</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                id="CertRadio8"
                value="false"
                v-model="BasicInfomation.isFGMP"
              />
              <label for="CertRadio8">否</label>
            </div>
          </div>
          <label class="col-md-3 col-form-label text-right"
            >有设备检查与检修制度</label
          >
          <div class="col-md-2">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                v-model="BasicInfomation.isCheckUp"
                id="CertRadio9"
                value="true"
                checked
              />
              <label for="CertRadio9">是</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                v-model="BasicInfomation.isCheckUp"
                id="CertRadio10"
                value="false"
              />
              <label for="CertRadio10">否</label>
            </div>
          </div>
        </div>
        <!-- radio-end -->
        <!-- radio-begin -->
        <div class="form-group row m-b-10">
          <label class="col-md-3 col-form-label text-right"
            >有安全隐患排查制度</label
          >
          <div class="col-md-2">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                v-model="BasicInfomation.isDangerCheck"
                id="CertRadio11"
                value="true"
                checked
              />
              <label for="CertRadio11">是</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                v-model="BasicInfomation.isDangerCheck"
                id="CertRadio12"
                value="false"
              />
              <label for="CertRadio12">否</label>
            </div>
          </div>
          <label class="col-md-3 col-form-label text-right">有工伤保险</label>
          <div class="col-md-2">
            <div class="radio radio-css radio-inline m-r-15">
              <input
                type="radio"
                v-model="BasicInfomation.isInjuryInsure"
                id="CertRadio13"
                value="true"
                checked
              />
              <label for="CertRadio13">是</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                v-model="BasicInfomation.isInjuryInsure"
                id="CertRadio14"
                value="false"
              />
              <label for="CertRadio14">否</label>
            </div>
          </div>
        </div>
        <!-- radio-end -->
        <hr class="m-b-40" />
        <h5 class="width-150">消防器材登记</h5>
        <div class="row form-group m-b-10">
          <div class="col-lg-4 col-md-6 form-inline">
            <label class="col-form-label text-right">灭火器数量：</label>
            <input
              class="form-control col-md-8"
              data-parsley-range="[5,10]"
              v-model="BasicInfomation.extinguisher"
            />
          </div>
          <div class="col-lg-4 col-md-6 form-inline">
            <label class="col-form-label text-right">消防栓数量：</label>
            <input
              class="col-md-8 form-control"
              v-model="BasicInfomation.hydrant"
            />
          </div>

          <label class="col-md-2 col-form-label text-right">消防泵数量：</label>
          <div class="col-lg-1 col-md-3">
            <input class="form-control" v-model="BasicInfomation.firePumps" />
          </div>
        </div>
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right">沙驰数量：</label>
          <div class="col-lg-1 col-md-3">
            <input
              class="form-control"
              data-parsley-range="[5,10]"
              v-model="BasicInfomation.basin"
            />
          </div>
          <label class="col-md-2 col-form-label text-right">其他数量：</label>
          <div class="col-lg-1 col-md-3">
            <input class="form-control" v-model="BasicInfomation.other" />
          </div>
        </div>
        <hr class="m-b-40" />
        <h5 class="text-right width-150">特种设备登记</h5>
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right"
            >锅炉数量(个)：</label
          >
          <div class="col-md-1">
            <input
              class="form-control"
              data-parsley-range="[5,10]"
              v-model="BasicInfomation.boiler"
            />
          </div>
          <label class="col-md-2 col-form-label text-right"
            >起重机数量(辆)：</label
          >
          <div class="col-md-1">
            <input class="form-control" v-model="BasicInfomation.crane" />
          </div>
          <label class="col-md-2 col-form-label text-right"
            >电梯数量(台)：</label
          >
          <div class="col-md-1">
            <input class="form-control" v-model="BasicInfomation.elevator" />
          </div>
        </div>
        <div class="row form-group m-b-10">
          <label class="col-md-2 col-form-label text-right"
            >专用机动车数量(辆)：</label
          >
          <div class="col-md-1">
            <input
              class="form-control"
              data-parsley-range="[5,10]"
              v-model="BasicInfomation.car"
            />
          </div>
          <label class="col-md-2 col-form-label text-right"
            >特种其他数量：</label
          >
          <div class="col-md-1">
            <input
              class="form-control"
              v-model="BasicInfomation.specialOther"
            />
          </div>
        </div>
        <label class="col-md-3 col-form-label"></label>
        <button
          type="submit"
          class="col-xs-1 btn btn-sm btn-primary m-b-5 width-100"
        >
          保存
        </button>
      </form>
    </div>
    <!-- end register-content -->
  </div>
</template>
<script>
// import PageOptions from '../config/PageOptions.vue'
import { userApi } from "@/api/system/userApi";
import { businessApi } from "@/api/system/businessApi";

export default {
  data() {
    return {
      treeData1: [
        {
          title: "node1",
          expanded: true,
          children: [
            {
              title: "node 1-1",
              expanded: true,
              children: [
                {
                  title: "node 1-1-1",
                },
                {
                  title: "node 1-1-2",
                },
                {
                  title: "node 1-1-3",
                },
              ],
            },
            {
              title: "node 1-2",
              children: [
                {
                  title: "<span style='color: red'>node 1-2-1</span>",
                },
                {
                  title: "<span style='color: red'>node 1-2-2</span>",
                },
              ],
            },
          ],
        },
      ],
      BasicInfomation: {
        name: "1",
        address: "1",
        regionId: "",
        gridId: "",
        industryId: "",
        safetyLevel: "0",
        businessLicense: "",
        creditCode: "1",
        legal: "1",
        legalIdCard: "1",
        legalTel: "1",
        mainProduct: "1",
        productionLicense: "",
        productionNum: "1",
        jobNum: "1",
        floorage: "1",
        outputValue: "1",
        isDangerous: "true",
        mainUserName: "1",
        mainTel: "1",
        mainIdCard: "1",
        mainIsCard: "true",
        mainValidity: "",
        mainSincerityLevel: "1",
        mainStandardLevel: "1",
        mainSafeLevel: "1",
        isSafeBook: "true",
        isEduTrain: "true",
        isFGMP: "true",
        isCheckUp: "true",
        isDangerCheck: "true",
        isInjuryInsure: "true",
        extinguisher: "1",
        hydrant: "1",
        firePumps: "1",
        other: "1",
        basin: "1",
        boiler: "1",
        crane: "1",
        elevator: "1",
        car: "1",
        specialOther: "1",
        longitude: "0",
        latitude: "0",
      },
      d: {
        file1: [],
        file2: [],
      },
    };
  },
  created() {
    // PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    // PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  mounted() {
    this.GetData();
  },
  methods: {
    //请求Api获取企业信息
    GetData() {
      let that = this;
      businessApi
        .GetData({
          //入参为地址栏传入的ID值
          id: that.$route.query.id,
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {});
    },
    GetSuccess(that, res) {
      if (res.success) {
        that.BasicInfomation = res.data;
        that.d.file1.push(res.data.businessLicense);
        that.d.file2.push(res.data.productionLicense);
        console.log(res.data.productionLicense);
        console.log(that.d.file1);
        // that.d.file2.push(res.data.productionLicense);
      } else {
        console.log(res);
      }
    },

    checkForm: function (e) {
      e.preventDefault();
      console.log(this.name);
      const params = {
        name: this.BasicInfomation.name,
        address: this.BasicInfomation.address,
        regionId: this.BasicInfomation.regionId,
        safetyLevel: this.BasicInfomation.safetyLevel,
        gridId: this.BasicInfomation.gridId,
        industryId: this.BasicInfomation.industryId,
        businessLicense: "",
        creditCode: this.BasicInfomation.creditCode,
        legal: this.BasicInfomation.legal,
        legalIdCard: this.BasicInfomation.legalIdCard,
        legalTel: this.BasicInfomation.legalTel,
        mainProduct: this.BasicInfomation.mainProduct,
        productionLicense: "",
        productionNum: this.BasicInfomation.productionNum,
        jobNum: this.BasicInfomation.jobNum,
        floorage: this.BasicInfomation.floorage,
        outputValue: this.BasicInfomation.outputValue,
        isDangerous: this.BasicInfomation.isDangerous,
        mainUserName: this.BasicInfomation.mainUserName,
        mainTel: this.BasicInfomation.mainTel,
        mainIdCard: this.BasicInfomation.mainIdCard,
        mainIsCard: this.BasicInfomation.mainIsCard,
        mainValidity: this.BasicInfomation.mainValidity,
        mainSincerityLevel: this.BasicInfomation.mainSincerityLevel,
        mainStandardLevel: this.BasicInfomation.mainStandardLevel,
        mainSafeLevel: this.BasicInfomation.mainSafeLevel,
        isSafeBook: this.BasicInfomation.isSafeBook,
        isEduTrain: this.BasicInfomation.isEduTrain,
        isFGMP: this.BasicInfomation.isFGMP,
        isCheckUp: this.BasicInfomation.isCheckUp,
        isDangerCheck: this.BasicInfomation.isDangerCheck,
        isInjuryInsure: this.BasicInfomation.isInjuryInsure,
        extinguisher: this.BasicInfomation.extinguisher,
        isSafehydrantBook: this.BasicInfomation.isSafehydrantBook,
        firePumps: this.BasicInfomation.firePumps,
        other: this.BasicInfomation.other,
        basin: this.BasicInfomation.basin,
        boiler: this.BasicInfomation.boiler,
        crane: this.BasicInfomation.crane,
        elevator: this.BasicInfomation.elevator,
        car: this.BasicInfomation.car,
        specialOther: this.BasicInfomation.specialOther,
        orderNum: this.BasicInfomation.orderNum,
      };

      //e.preventDefault();
      let that = this;
      //新建公司信息//
      userApi
        .CreateCompany(params)
        .then((res) => that.createSuccess(that, res))
        .finally(() => {});
    },
    createSuccess(that, res) {
      if (res.success == false && res.code == 500) {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: res.message,
        });
      } else {
        console.log(res);
      }
    },
    //文件上传成功后返回的文件组
    finishAll(e) {
      console.log(e);
      this.BasicInfomation.businessLicense = e[0].res.data.fileInfo.id;
      console.log(e[0].res.data.fileInfo.id);
    },
    finishAll1(e) {
      console.log(e);
      this.BasicInfomation.productionLicense = e[0].res.data.fileInfo.id;
      console.log(e[0].res.data.fileInfo.id);
    },
  },
};
</script>
